/*
 * @Author: dongjia
 * @Date: 2021-12-17 16:04:40
 * @LastEditTime: 2021-12-17 17:07:52
 * @LastEditors: aleaner
 * @Description: 雷达图表
 * @FilePath: \organization-admin\assets\echarts\radar-chart.js
 * 怎么可能会有bug！！！
 */
import Chart from "./chart";
import merge from 'merge';
//图表
import * as echarts from "echarts/core";
import { RadarChart } from "echarts/charts";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, LegendComponent, RadarChart, CanvasRenderer]);


export default class MyRadarChart extends Chart {
  //悬浮展示
  tooltip = {
    trigger: "item",
    formatter: (params, ticket, callback) => {
      return `${params.name} ${params.value}`;
    },
  };
  //图例
  legend = {
    right: "0",
    orient: "vertical",
    top: "center",
    icon: "circle",
    formatter: (name) => {
      const findData = this.data.find((item) => item.name === name);
      if (!findData) return name;
      return `${name} |    ${findData.value}`;
    },
  }
  // 雷达图配置
  radar = {
    indicator: [],
  };
  //图形
  series = {
    name: "",
    type: "radar",
    label: {
      show: false,
    },
    emphasis: {
      label: {
        show: false,
        fontSize: "20",
        fontWeight: "bold",
      },
    },
    data: [
      {
        value: [],
        name: ""
      }
    ],
  };


  constructor(options = {}) {
    super(options);

    //初始化
    this.init(options);
  }

  //初始化
  init(options) {
    //校验+合并参数
    const validData = this.initValidatorAndMerge(options);

    //获取图表数据
    const { container, data, resize } = validData;
    //初始化实例
    this.$myChart = echarts.init(container);
    //设置图表数据
    this.setOption(data);

    //监听容器resize
    if (resize) this.addResize();
  }

  //初始化校验+合并参数
  initValidatorAndMerge(options) {
    const {
      container,
      color,
      radarConfig,
      tooltip,
      showTooltip = true,
      legend,
      showLegend = true,
      series,
      data,
      resize = true
    } = options;


    //容器
    this.container = this.validateContainer(container);
    //data
    this.validateData(data);

    //tooltip
    this.showTooltip = showTooltip;
    if (showTooltip && tooltip) merge.recursive(this.tooltip, this.validateTooltip(tooltip));
    //legend
    this.showLegend = showLegend;
    if (showLegend && legend) merge.recursive(this.legend, this.validateLegend(legend));
    //series
    if (series) merge.recursive(this.series, this.validateSeries(series));
    //resize
    this.resize = this.validateResize(resize);
    //颜色
    if (color) this.color = this.validateColor(color);
    // 雷达配置
    if (radarConfig) merge.recursive(this.radar, this.validateRadarConfig(radarConfig))

    //返回出去
    return {
      container,
      color,
      radarConfig,
      tooltip,
      showTooltip,
      legend,
      showLegend,
      series,
      data,
      resize
    }
  }

  //设置数据
  setOption(data) {
    this.data = this.validateData(data); //挂载，方便formatter查询
    this.series.data[0].value = data
    console.log(this.series, 'this.series')
    //配置对象
    const options = {
      color: this.color,
      radar: this.radar,
      series: this.series
    };

    //是否显示组件
    if (this.showLegend) options.legend = this.legend;
    if (this.showTooltip) options.tooltip = this.tooltip;

    //设置
    this.$myChart.setOption(options);
  }

}

