<!--
 * @Author: mulingyuer
 * @Date: 2021-10-21 11:54:14
 * @LastEditTime: 2022-01-24 10:09:23
 * @LastEditors: aleaner
 * @Description: 使用统计-客户端打开次数统计
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\organization-admin\components\DataStats\ClientOpenStats.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="客户端打开率">
    <template #headRight>
      <div class="header-right">

        <el-button class="primary-plain" plain size="mini" @click="onExport">导出
        </el-button>
      </div>
    </template>
    <MyDatePicker v-model="form.time" ref="MyDatePicker" @change="getMemberRevenue" />
    <div class="content" ref="chartContent" v-loading="loading">
    </div>
  </BDCard>
</template>

<script>
import BDCard from "./BDCard.vue";
import MyDatePicker from "./MyDatePicker.vue";
import {
  platformOpenStat,
  documentPlatformOpenList,
} from "../../api/data-stats/usage-stats.js";
import MyBarChart from "../../assets/echarts/bar-chart";
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        time: [-1, -1],
      },
      mrData: {}, //获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
      // 柱状图所在tab标识
      tabPage: "usageStats",
      extraData: {
        // 总人数数组数据
        totals: [],
        // 小程序使用人数数组数据
        mp_use_totals: [],
        // h5使用人数数组数据
        h5_use_totals: [],
      },
    };
  },
  methods: {
    //获取统计数据
    getMemberRevenue() {
      this.loading = true;
      this.init = false;
      const postData = this.getFormData();
      platformOpenStat(postData)
        .then((res) => {
          const { data } = res;
          this.mrData = data;

          this.transformData();
          this.initChart();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      const mp_data = this.mrData.map((item) => {
        return {
          value: item.mp_rate,
          total: item.members_count,
          mp_count: item.mp_count,
        };
      });
      const h5_data = this.mrData.map((item) => {
        return {
          value: item.h5_rate,
          total: item.members_count,
          mp_count: item.h5_count,
        };
      });
      this.chartData = Object.assign({}, { mp_data, h5_data });
      console.log(this.chartData, "this.chartData");
      this.xData = this.mrData.map((item) => item.name);
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        try {
          this.$chart = new MyBarChart({
            container: this.$refs.chartContent,
            data: this.chartData,
            xData: this.xData,
            tabPage: this.tabPage,
            extraData: this.extraData,
            legend: {
              right: 0,
              top: 0,
              icon: "rect",
              data: ["微信小程序", "H5 微网站"],
            },
            yAxis: {
              type: "value",
              name: "单位：%",
            },
            grid: {
              left: "20px",
              right: "20px",
              bottom: "50px",
              top: "40px",
              containLabel: true,
            },
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
      }
    },
    // 图标统计数据导出
    onExport() {
      const postData = this.getFormData();
      documentPlatformOpenList(postData).catch((error) => {});
    },
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getMemberRevenue();
    });
  },
  components: {
    BDCard,
    MyDatePicker,
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 300px;
}
.header-right {
  display: flex;
  align-items: center;
  .primary-plain {
    margin-left: 20px;
  }
}
</style>
