/*
 * @Author: dongjia
 * @Date: 2021-12-08 16:28:16
 * @LastEditTime: 2022-01-13 10:58:19
 * @LastEditors: aleaner
 * @Description: 使用统计接口
 * @FilePath: \organization-admin\api\data-stats\usage-stats.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

//获取客户端打开率统计数据
export const platformOpenStat = data => {
  return api({
    url: "/admin/cyc/stat/platformOpenStat",
    method: "post",
    data
  })
}

// 获取会员打开数统计数据
export const userOpenStat = data => {
  return api({
    url: "/admin/cyc/stat/userOpenStat",
    method: "post",
    data
  })
}

// 获取最近更新时间
export const useStat = data => {
  return api({
    url: "/admin/cyc/stat/useStat",
    method: "post",
    data
  })
}

// 客户端打开率统计导出
export const documentPlatformOpenList = data => {
  return fetchFile({
    url: "/admin/cyc/stat/platformOpenStatExport",
    method: "post",
    data,
    download: true
  });
};

// 会员打开数统计导出
export const documentUserOpenStatList = data => {
  return fetchFile({
    url: "/admin/cyc/stat/userOpenStatExport",
    method: "post",
    data,
    download: true
  });
};
