<!--
 * @Author: dongjia
 * @Date: 2021-12-08 15:54:38
 * @LastEditTime: 2022-01-24 10:08:45
 * @LastEditors: aleaner
 * @Description: 数据统计-概况
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\organization-admin\views\data-stats\survey.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="page-index" v-loading="loading">
    <div class="page-content" v-if="baseData && baseData.init && baseData.list.length">
      <div class="page-header">
        <div class="header-control">
          <div class="select-form">
            <p class="form-label">选择组织：</p>
            <el-select size="small" v-model="filterForm.organization_id" @change="getUpdateTime"
              placeholder="组织名称">
              <el-option v-for="item in organizationOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div>
            <el-button class="primary-plain" plain size="small" @click="onExport">导出
            </el-button>
            <el-button type="primary" size="small" @click="handleEdit">编辑</el-button>
          </div>
        </div>
        <div style="display: flex;align-items: center;margin-top: 16px;">
          <div class="update-time" v-if="baseData.update_time"><span style="margin-right: 10px;"
              class="el-icon-info"></span>更新时间：{{baseData.update_time}}</div>
          <div class="update-time" v-if="baseData.update_time"><span style="margin-right: 10px;"
              class="el-icon-info"></span>拖拽模块调整排序</div>
        </div>
      </div>
      <el-scrollbar class="page-main">
        <draggable v-if="!loading" v-model="baseData.list" class="main-drag" animation="500"
          draggable=".list-item" @sort="listUpdate">
          <div class="list-item cursor-pointer" :style="{
          width: ['bar','line','scatter'].includes(item.figure)?'calc(100% - 24px)':'calc((100% / 3) - 24px)'
        }" v-for="(item,index) in baseData.list" :key="index">
            <SurveyItemChart :organizationId="filterForm.organization_id" :itemData="item"
              :dataChange.sync="item" @listUpdate="listUpdate" />
          </div>
        </draggable>
      </el-scrollbar>

    </div>
    <div class="default-page" v-else>
      <div class="main-content">
        <img src="../../assets/images/data-stats/default-page-icon.png" />
        <el-button type="primary" @click="handleEdit">开启资料统计</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import SurveyItemChart from "../../components/DataStats/SurveyItemChart.vue";
import {
  overview,
  organizationList,
  updateStatConfig,
  documentOverviewList,
} from "../../api/data-stats/survey.js";

export default {
  components: { draggable, SurveyItemChart },
  data() {
    return {
      loading: false,
      showQrCode: false,
      updateTime: "",
      baseData: null,
      organizationOptions: [],
      filterForm: {
        organization_id: "",
      },
    };
  },
  created() {
    this.getUpdateTime();
    this.getOrganizationList();
  },
  methods: {
    // 获取组织选项列表数据
    getOrganizationList() {
      organizationList()
        .then((res) => {
          const { data } = res;
          this.organizationOptions = [{ label: "全部", value: "" }, ...data];
          this.$store.commit("dataStats/setOrganizationList", data);
        })
        .catch((err) => {});
    },
    // 获取更新时间
    getUpdateTime() {
      this.loading = true;
      const filter = { ...this.filterForm };
      overview(filter)
        .then((res) => {
          const { data } = res;
          this.baseData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 前往添加数据
    handleEdit() {
      this.$router.push({ name: "statEdit" });
    },
    // 全局数据导出
    onExport() {
      return documentOverviewList(this.filterForm).catch((err) => {});
    },
    // 更新图标配置
    listUpdate() {
      const config = this.baseData.list.map((item) => ({
        field: item.field,
        label: item.label,
        figure: item.figure,
        is_checked: 1,
      }));
      updateStatConfig({ config });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-index {
  margin-top: 24px;
  width: 100%;
  height: 100%;
  position: relative;

  .page-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .page-header {
      padding-bottom: 24px;
      z-index: 1;
      box-shadow: 0 8px 8px -10px rgba(0, 0, 0, 0.3);
      .header-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .select-form {
          display: flex;
          align-items: center;
          .form-label {
            font-size: 12px;
            color: #919398;
            line-height: 24px;
            margin-right: 12px;
          }
        }
      }

      .update-time + .update-time {
        margin-left: 27px;
      }

      .update-time {
        font-size: 12px;
        color: #919398;
        line-height: 24px;
      }
    }

    .page-main {
      background: #f2f3f5;
      flex-grow: 1;
      height: 0;
      z-index: 0;

      ::v-deep.el-scrollbar__wrap {
        min-height: 375px;
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }

      .main-drag {
        padding: 24px 44px 0 24px;
        margin-left: -24px;
        width: calc(100% + 24px);
        display: flex;
        flex-wrap: wrap;

        .list-item {
          height: 351px;
          margin: 0 0 24px 24px;
          background-color: #fff;
          border-radius: 4px;
          flex-shrink: 0;
        }
      }
    }
  }

  .default-page {
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f3f5;
    .main-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 94px;
        height: 94px;
        margin-bottom: 35px;
      }
    }
  }
}
.qrcode-area {
  position: fixed;
  right: 0;
  bottom: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3971fb;
  border-radius: 2px;
  transition: all 0.5 ease-in-out;

  .sm-img {
    width: 32px;
    height: 32px;
  }

  .show-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 200px;
      height: 200px;
    }
    p {
      margin-top: 4px;
      font-size: 12px;
      color: #ffffff;
      line-height: 24px;
    }
  }
  .el-icon-close {
    position: absolute;
    top: 8px;
    left: 8px;
    color: #ffffff;
    font-size: 12px;
  }

  &.default {
    width: 48px;
    height: 48px;
  }
  &.actived {
    padding: 23px 25px 9px;
  }
}
</style>