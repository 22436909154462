<!--
 * @Author: dongjia
 * @Date: 2021-12-08 15:47:11
 * @LastEditTime: 2021-12-20 16:50:47
 * @LastEditors: aleaner
 * @Description: 组织用户数据统计
 * @FilePath: \organization-admin\views\data-stats\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="page-index">
    <el-tabs v-model="activeName">
      <el-tab-pane v-for="(item,i) in tabsArr" :key="item.value" :label="item.name" :name="item.value">
      </el-tab-pane>
    </el-tabs>
    <Survey v-if="activeName === 'survey'" />
    <usageStats v-if="activeName === 'usageStats'" />
  </div>
</template>

<script>
import Survey from "./survey.vue";
import usageStats from "./usage-stats.vue";
export default {
  components: { Survey, usageStats },
  data() {
    return {
      activeName: "survey",
      tabsArr: [
        { name: "会员资料统计", value: "survey" },
        { name: "使用统计", value: "usageStats" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.page-index {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
</style>