<!--
 * @Author: dongjia
 * @Date: 2021-12-14 16:36:46
 * @LastEditTime: 2022-01-24 10:10:04
 * @LastEditors: aleaner
 * @Description: 概览统计图表项
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\organization-admin\components\DataStats\SurveyItemChart.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard>
    <template #headLeft>
      <div class="header-left">
        <span class="title">{{cardData.label}}</span>
        <img class="update-btn" @click="openTypeSelector = true"
          src="../../assets/images/data-stats/update-icon.png" />
      </div>
    </template>
    <template #headRight>
      <el-button class="primary-plain" plain size="mini" @click="onExport">导出
      </el-button>
    </template>
    <div class="card-container" ref="chartContent" v-loading="loading">
    </div>
    <FigureTypeSelector v-model="openTypeSelector" :figure="cardData.figure"
      :figureSelected.sync="cardData.figure" @change="$emit('listUpdate')" />
  </BDCard>
</template>

<script>
import BDCard from "./BDCard.vue";
import FigureTypeSelector from "./FigureTypeSelector.vue";
import { itemStats, documentStatList } from "../../api/data-stats/survey.js";
import MyBarChart from "../../assets/echarts/bar-chart";
import MyLineChart from "../../assets/echarts/line-chart";
import MyPieChart from "../../assets/echarts/pie-chart";
import MyScatterChart from "../../assets/echarts/scatter-chart";
import MyRadarChart from "../../assets/echarts/radar-chart";
export default {
  components: { BDCard, FigureTypeSelector },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    organizationId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {},
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
      // 雷达配置数据
      radarConfig: {},
      openTypeSelector: false,
    };
  },
  created() {
    this.$nextTick(() => {
      if (this.cardData) {
        this.transformData();
        this.initChart();
      }
    });
  },
  computed: {
    cardData: {
      get() {
        return this.itemData;
      },
      set(val) {
        this.$emit("dataChange", val);
      },
    },
  },
  watch: {
    cardData: {
      deep: true,
      handler(val) {
        this.$emit("update:dataChange", val);
      },
    },
    "cardData.figure"(val) {
      this.$nextTick(() => {
        this.destroyChart();
        this.transformData();
        this.initChart();
      });
    },
  },
  methods: {
    //获取统计数据
    getMemberRevenue() {
      this.loading = true;
      this.init = false;
      // const postData = this.getFormData();
      itemStats()
        .then((res) => {
          const { data } = res;
          this.cardData.stat = data;

          this.transformData();
          this.initChart();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form };
      // const [start_time = "", end_time = ""] = data.time;
      // Object.assign(data, { start_time, end_time });
      // Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      if (["pie", "annular", "rose"].includes(this.cardData.figure)) {
        this.chartData = this.cardData.stat.map(({ label, count }) => ({
          name: label,
          value: Math.round((count * 100) / this.cardData.total),
        }));
      } else if (this.cardData.figure === "radar") {
        this.chartData = this.cardData.stat.map(({ count }) => count);
        const max = JSON.parse(JSON.stringify(this.chartData)).sort(
          (a, b) => b - a
        )[0];
        const indicator = this.cardData.stat.map((item) => ({
          name: item.label,
          max,
        }));
        this.radarConfig = Object.assign(this.radarConfig, { indicator });
      } else {
        this.chartData = this.cardData.stat.map((item) => item.count);
        this.xData = this.cardData.stat.map((item) => item.label);
      }
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        try {
          switch (this.cardData.figure) {
            case "bar":
              this.$chart = new MyBarChart({
                container: this.$refs.chartContent,
                data: this.chartData,
                xData: this.xData,
              });
              break;
            case "line":
              this.$chart = new MyLineChart({
                container: this.$refs.chartContent,
                data: this.chartData,
                xData: this.xData,
              });
              break;
            case "annular":
              this.$chart = new MyPieChart({
                container: this.$refs.chartContent,
                data: this.chartData,
                series: {
                  radius: ["50%", "70%"],
                },
              });
              break;
            case "rose":
              this.$chart = new MyPieChart({
                container: this.$refs.chartContent,
                data: this.chartData,
                series: {
                  radius: [10, 100],
                  roseType: "radius",
                  itemStyle: {
                    borderRadius: 6,
                  },
                },
              });
              break;
            case "pie":
              this.$chart = new MyPieChart({
                container: this.$refs.chartContent,
                data: this.chartData,
              });
              break;
            case "scatter":
              this.$chart = new MyScatterChart({
                container: this.$refs.chartContent,
                data: this.chartData,
                xData: this.xData,
              });
              break;
            case "radar":
              this.$chart = new MyRadarChart({
                container: this.$refs.chartContent,
                data: this.chartData,
                radarConfig: this.radarConfig,
              });
              break;
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setChartOption();
      }
    },
    // 图标统计数据导出
    onExport() {
      let { figure, field, label } = this.cardData;
      let requestData = {
        organization_id: this.organizationId,
        figure,
        field,
        label,
      };
      return documentStatList(requestData).catch((err) => {});
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
        this.$chart = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.header-left {
  display: flex;
  align-items: center;
  .update-btn {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-left: 4px;
  }
}

.card-container {
  height: 100%;
}
</style>