<!--
 * @Author: dongjia
 * @Date: 2021-12-13 17:21:35
 * @LastEditTime: 2022-01-12 11:24:47
 * @LastEditors: aleaner
 * @Description: 图形类型选择弹窗
 * @FilePath: \organization-admin\components\DataStats\FigureTypeSelector.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="选择图表类型" :visible.sync="show" width="500px" class="dialog-vertical cert-dialog"
    append-to-body @open="onOpen" @close="colse">
    <div class="content">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" size="medium"
        class="medium-form">
        <div class="left">
          <el-form-item prop="figure" label="图表类型：">
            <el-select v-model="form.figure" placeholder="请选择图表类型">
              <el-option v-for="op in typeArr" :key="op.label" :label="op.name" :value="op.label">
                <span>{{ op.name }}</span>
                <img class="option-icon" :src="form.figure === op.label?op.actived_icon:op.default_icon" />
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="colse">取消</el-button>
      <el-button size="small" type="primary" @click="onConfirm('ruleForm')">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "dialogColse",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    figure: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        figure: "",
      },
      rules: {
        figure: [
          { required: true, message: "请选择图表类型", trigger: "change" },
        ],
      },
      typeArr: [
        {
          name: "基础柱状图",
          label: "bar",
          default_icon: require("../../assets/images/data-stats/bar.png"),
          actived_icon: require("../../assets/images/data-stats/bar__actived.png"),
        },
        // { name: "折线图", label: "line" },
        {
          name: "环形图",
          label: "annular",
          default_icon: require("../../assets/images/data-stats/annular.png"),
          actived_icon: require("../../assets/images/data-stats/annular__actived.png"),
        },
        {
          name: "饼图",
          label: "pie",
          default_icon: require("../../assets/images/data-stats/pie.png"),
          actived_icon: require("../../assets/images/data-stats/pie__actived.png"),
        },
        {
          name: "南丁格尔玫瑰图",
          label: "rose",
          default_icon: require("../../assets/images/data-stats/rose.png"),
          actived_icon: require("../../assets/images/data-stats/rose__actived.png"),
        },
        // { name: "雷达图", label: "radar" },
        {
          name: "基础散点图",
          label: "scatter",
          default_icon: require("../../assets/images/data-stats/scatter.png"),
          actived_icon: require("../../assets/images/data-stats/scatter__actived.png"),
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("dialogColse", value);
      },
    },
  },
  methods: {
    onOpen() {
      this.form.figure = this.figure;
    },
    colse() {
      this.show = false;
      this.$emit("cancel");
    },
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("update:figureSelected", this.form.figure);
          this.$emit("change");
          this.colse();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-select-dropdown__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.option-icon {
  width: 16px;
  height: 16px;
}
</style>