<!--
 * @Author: dongjia
 * @Date: 2021-12-08 15:55:31
 * @LastEditTime: 2022-01-13 14:39:21
 * @LastEditors: aleaner
 * @Description: 数据统计-使用统计
 * @FilePath: \organization-admin\views\data-stats\usage-stats.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="page-index" @click="showQrCode = false">
    <div class="update-time" v-if="updateTime"><span style="margin-right: 14px;"
        class="el-icon-info"></span>更新时间：{{updateTime}}</div>
    <ClientOpenStats />
    <MemberOpenStats />
    <!-- 二维码区域 -->
    <div class="qrcode-area" :class="showQrCode?'actived':'default'" @click.stop="() => {}">
      <img @click.stop="showQrCode = true" class="sm-img cursor-pointer" v-if="!showQrCode"
        src="../../assets/images/data-stats/qr-code-icon.png" />
      <div class="show-code" v-if="showQrCode">
        <div class="qrcode-box">
          <img src="../../assets/images/data-stats/qr-code.png" />
        </div>
        <p>查看更多运营数据</p>
      </div>
      <span v-if="showQrCode" class="el-icon-close cursor-pointer" @click="showQrCode = false"></span>
    </div>
  </div>
</template>

<script>
import ClientOpenStats from "../../components/DataStats/ClientOpenStats.vue";
import MemberOpenStats from "../../components/DataStats/MemberOpenStats.vue";

import { useStat } from "../../api/data-stats/usage-stats.js";

export default {
  components: { ClientOpenStats, MemberOpenStats },
  data() {
    return {
      showQrCode: false,
      updateTime: "",
    };
  },
  created() {
    this.getUpdateTime();
  },
  methods: {
    // 获取更新时间
    getUpdateTime() {
      useStat()
        .then((res) => {
          this.updateTime = res.data.update_time;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.page-index {
  padding: 24px;
  margin-top: 24px;
  width: 100%;
  height: 100%;
  background: #f2f3f5;
  position: relative;

  .update-time {
    font-size: 12px;
    color: #919398;
    line-height: 24px;
    margin-bottom: 16px;
  }
}
.qrcode-area {
  position: fixed;
  right: 0;
  bottom: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3971fb;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out; /* Firefox 4 */
  -webkit-transition: all 0.3s ease-in-out; /* Safari and Chrome */
  -o-transition: all 0.3s ease-in-out; /* Opera */
  &.default {
    width: 48px;
    height: 48px;
  }
  &.actived {
    width: 250px;
    height: 260px;
    padding: 23px 25px 9px;
  }
  .sm-img {
    width: 32px;
    height: 32px;
  }

  .show-code {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .qrcode-box {
      width: 100%;
      position: relative;
      &::before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    p {
      margin-top: 4px;
      font-size: 12px;
      color: #ffffff;
      line-height: 24px;
    }
  }
  .el-icon-close {
    position: absolute;
    top: 8px;
    left: 8px;
    color: #ffffff;
    font-size: 12px;
  }
}
</style>