/*
 * @Author: dongjia
 * @Date: 2021-12-08 16:28:49
 * @LastEditTime: 2022-01-13 10:38:53
 * @LastEditors: aleaner
 * @Description: 概况接口
 * @FilePath: \organization-admin\api\data-stats\survey.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';
import fetchFile from "@/base/utils/download";

//获取概况基本信息
export const overview = data => {
  return api({
    url: "/admin/cyc/stat/overview",
    method: "post",
    data
  })
}

//组织选项列表
export const organizationList = data => {
  return api({
    url: "/admin/cyc/stat/organizations",
    method: "post",
    data
  })
}

// 获取单个图表统计数据
export const itemStats = data => {
  return api({
    url: "/admin/cyc/stat/stat",
    method: "post",
    data
  })
}

// 缓存图表配置
export const updateStatConfig = data => {
  return api({
    url: "/admin/cyc/stat/updateUserStatConfig",
    method: "post",
    data
  })
}

// 全局数据统计导出
export const documentOverviewList = data => {
  return fetchFile({
    url: "/admin/cyc/stat/overviewExport",
    method: "post",
    data,
    download: true
  });
};

// 单个图表统计数据导出
export const documentStatList = data => {
  return fetchFile({
    url: "/admin/cyc/stat/statExport",
    method: "post",
    data,
    download: true
  });
};