<!--
 * @Author: dongjia
 * @Date: 2021-12-08 16:51:19
 * @LastEditTime: 2022-01-24 10:09:45
 * @LastEditors: aleaner
 * @Description: 会员打开次数
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\organization-admin\components\DataStats\MemberOpenStats.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="会员打开次数">
    <template #headRight>
      <div class="header-right">
        <el-input v-model="form.user" size="small" placeholder="请输入会员名称" suffix-icon="el-icon-search"
          @change="getMemberRevenue"></el-input>
        <el-button class="primary-plain" plain size="mini" @click="onExport">导出个人
        </el-button>
        <el-button class="primary-plain" plain size="mini" @click="onExport('all')">导出全部会员
        </el-button>
      </div>
    </template>
    <MyDatePicker v-model="form.time" ref="MyDatePicker" @change="getMemberRevenue" />
    <div class="card-container">
      <div class="content" ref="chartContent" v-loading="loading">
      </div>
    </div>
  </BDCard>
</template>

<script>
import BDCard from "./BDCard.vue";
import MyDatePicker from "./MyDatePicker.vue";
import {
  userOpenStat,
  documentUserOpenStatList,
} from "../../api/data-stats/usage-stats.js";
import MyLineChart from "../../assets/echarts/line-chart";
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        user: "",
        time: [-1, -1],
      },
      mrData: {}, //获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
      yAxis: {
        type: "value",
        name: "单位：次数",
      },
    };
  },
  methods: {
    //获取统计数据
    getMemberRevenue() {
      this.loading = true;
      this.init = false;
      const postData = this.getFormData();
      userOpenStat(postData)
        .then((res) => {
          const { data } = res;
          this.mrData = data;

          this.transformData();
          this.initChart();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      const mp_data = this.mrData.map((item) => item.mp_count);
      const h5_data = this.mrData.map((item) => item.h5_count);
      this.chartData = Object.assign({}, { mp_data, h5_data });
      this.xData = this.mrData.map((item) => item.day_time);
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        try {
          this.$chart = new MyLineChart({
            container: this.$refs.chartContent,
            data: this.chartData,
            xData: this.xData,
            yAxis: this.yAxis,
            legend: {
              right: 0,
              top: 0,
              data: ["微信小程序", "H5 微网站"],
            },
            grid: {
              left: "20px",
              right: "0",
              bottom: "0",
              top: "40px",
              containLabel: true,
            },
          });
        } catch (err) {
          console.log(err, "err");
        }
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
      }
    },
    // 图标统计数据导出
    onExport(type) {
      const postData = this.getFormData();
      // 导出全部多加参数
      if(type === 'all') {
        postData.for_all = 1
        postData.user = ''
        console.log(postData)

      }
      documentUserOpenStatList(postData).catch((err) => {});
    },
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getMemberRevenue();
    });
  },
  components: {
    BDCard,
    MyDatePicker,
  },
};
</script>

<style lang="scss" scoped>
.header-right {
  display: flex;
  align-items: center;
  .primary-plain {
    margin-left: 20px;
  }
}
.card-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    min-width: 0;
    flex-grow: 1;
    height: 250px;
  }
}
</style>
